import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "upload-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadVideoItem = _resolveComponent("UploadVideoItem")!
  const _component_VideoUploader = _resolveComponent("VideoUploader")!
  const _component_el_main = _resolveComponent("el-main")!

  return (_openBlock(), _createBlock(_component_el_main, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_VideoUploader, {
          multiple: "",
          type: "video"
        }, {
          video: _withCtx(({video}) => [
            _createVNode(_component_UploadVideoItem, { video: video }, null, 8, ["video"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}