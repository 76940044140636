
import { defineComponent } from 'vue';

import VideoUploader from '@/components/video-uploader/Index.vue';
import UploadVideoItem from './components/UploadVideoItem.vue';

export default defineComponent({
  components: {
    VideoUploader,
    UploadVideoItem
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  }
});
